import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from './app.component.service';
import { CLOUDINARY_API_BASE, CLOUDINARY_BASE_URL, CLOUDINARY_IMAGE, CLOUDINARY_VIDEO } from './global/global';
import { folderName, setAwsCognitoCred } from './NgRx/actions/posts.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Admin';
  state: any;
  constructor(
    private service: AppService,
    private store: Store
  ) {}

  ngOnInit(){
    this.getAwsCognitoCreds();
    this.getFolderNameByCognito();
  }

  getCloudinaryCreds(){
    this.service.getCludinaryCredsApi()
    .subscribe((res)=>{
      let cloudinaryCreds = res.data;

      //Inserting Dynamic Links to Ngrx Store
      const IMAGE_LINK = `${CLOUDINARY_BASE_URL}${cloudinaryCreds.cloudName}${CLOUDINARY_IMAGE}`
      const IMAGE_BASE_LINK = `${CLOUDINARY_API_BASE}${cloudinaryCreds.cloudName}${CLOUDINARY_IMAGE}`
      const VIDEO_LINK = `${CLOUDINARY_BASE_URL}${cloudinaryCreds.cloudName}${CLOUDINARY_VIDEO}`
      const VIDEO_BASE_LINK = `${CLOUDINARY_API_BASE}${cloudinaryCreds.cloudName}${CLOUDINARY_VIDEO}`

      cloudinaryCreds = {
        ...cloudinaryCreds,
        IMAGE_LINK,
        IMAGE_BASE_LINK,
        VIDEO_LINK,
        VIDEO_BASE_LINK
      }
      this.store.dispatch(setAwsCognitoCred(cloudinaryCreds))
      // this.store.dispatch({type:SET_CLOUDINARY_CREDS, payload: cloudinaryCreds})

    })
  }

  getAwsCognitoCreds(){
    this.service.getAwsCognitoAppConfigApi()
    .subscribe((res)=>{
      let awsCognitoCreds = res.data;

      //Inserting Dynamic Links to Ngrx Store
      const BASE_URL = res.data.baseUrl;
      const IMAGE_BASE_URL = res.data.imageBaseUrl;
      const NON_TRANSFORM_URL = res.data.nonTransformUrl

      awsCognitoCreds = {
        BASE_URL,
        IMAGE_BASE_URL,
        NON_TRANSFORM_URL
      }
      this.store.dispatch(setAwsCognitoCred(awsCognitoCreds))
    })
  }

  getFolderNameByCognito(){
    this.service.uploadVideoAwsCognito()
    .subscribe((res)=>{
      let awsCognitoCreds = res.data;

      //Inserting Dynamic Links to Ngrx Store
      const VIRTUAL_ORDER_FOLDER = res.data.virtualOrderFolder;
      const IMAGE_BUCKET = res.data.imageBucket

      awsCognitoCreds = {
        VIRTUAL_ORDER_FOLDER,
        IMAGE_BUCKET
      }
      this.store.dispatch(folderName(awsCognitoCreds))

    })
  }
}

